import React from 'react'
import PropTypes from 'prop-types'

import ElementImageFile from '@components/global/element/ImageFile'
import BgPattern from '/content/assets/choose-a-website/diagonal-line-pattern.jpg'
import * as cx from './BuiltInPackages.module.scss'

import parse from 'html-react-parser'

const BuiltInPackages = ({
  title = 'All Packages Contain',
  subtitle = 'Over 30 Built-In <br />Real Estate Features:',
  wrapperClassName = '',
  image,
  items,
}) => {
  return (
    <section
      className={`${cx.wrapper} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${BgPattern})` }}
    >
      <div className={cx.holder}>
        <ElementImageFile
          className={cx.image}
          src={image}
          alt={'All Packages Contain Over 30 Built-In Real Estate Features'}
          lazy={true}
        />
        <div className={cx.border}></div>
        <div className={cx.gradient}></div>
        <div className={cx.content}>
          <div className={cx.heading}>
            <h2>
              <span className="subtitle-1">{title}</span>
              <span className="heading-5">{parse(subtitle)}</span>
            </h2>
          </div>

          <ul>
            {items.map((item, index) => {
              return (
                <li key={index}>
                  <span className="default-body large">
                    {item?.built_in_name}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

BuiltInPackages.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  wrapperClassName: PropTypes.string,
  image: PropTypes.any,
  items: PropTypes.array,
}

export default BuiltInPackages
