import React from 'react'
import PropTypes from 'prop-types'

import FormFreeConsultation from '../../FreeConsultation'
import WrapperBg from '/content/assets/bottom-form/default.jpg'
import WrapperBgAlt from '/content/assets/bottom-form/LCF02-bg.jpg'
import ImgModel from '/content/assets/bottom-form/model.jpg'
import * as cx from './Version1.module.scss'

const FormsBottomVersion1 = ({
  background = '',
  backgroundVersion = 'v1',
  model = `${ImgModel}`,
  title = 'Get in touch with us for a',
  subtitle = 'Free Consultation',
  alt = false,
  imageClassName = '',
  formLocation,
}) => {
  return (
    <section
      className={`${cx.wrapper} ${alt ? cx.alt : ''}`}
      style={{
        backgroundImage: `url(${
          background !== ''
            ? background
            : backgroundVersion === 'v2'
            ? WrapperBgAlt
            : WrapperBg
        })`,
        backgroundPosition: backgroundVersion === 'v2' ? 'top right' : 'center',
      }}
    >
      <div className={cx.holder}>
        <div className="row">
          <div
            className={`col form-bottom-model ${cx.model} ${imageClassName}`}
          >
            <canvas
              width={480}
              height={640}
              style={{ backgroundImage: `url(${model})` }}
            ></canvas>
          </div>
          <div className={`col form-bottom-container ${cx.formContainer}`}>
            <div className={cx.form}>
              <h2>
                <span className="subtitle-7">{title}</span>
                <span className="heading-5">{subtitle}</span>
              </h2>
              <FormFreeConsultation
                submitLabel="I want a stunning website!"
                leadName="Bottom FSU"
                isEmailPhoneMerger={true}
                isMessageTextarea={true}
                formLocation={formLocation}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FormsBottomVersion1.propTypes = {
  background: PropTypes.string,
  backgroundVersion: PropTypes.string,
  model: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alt: PropTypes.bool,
  imageClassName: PropTypes.string,
}

export default FormsBottomVersion1
