import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'
import parse from 'html-react-parser'
import { modifyImageKeys } from '@utils/modifyImageKeys'

import CheckItems from '@components/global/CheckItems'
import ElementImageFile from '@components/global/element/ImageFile'
import SvgStar from '@src/svg/star.inline'
import * as cx from './CompareTab.module.scss'

const features = [
  {
    check: false,
    content: 'Built-in features and hundreds of custom options',
  },
  {
    check: false,
    content: 'Custom Homepage',
  },
  {
    check: false,
    content: 'Custom Inner Pages',
  },
  {
    check: false,
    content: 'Custom Real Estate Tools',
  },
  {
    check: false,
    content: 'Custom Add-Ons',
  },
]

const siteGradient = [
  'bg-gradient-aix',
  'bg-gradient-skys-the-limit',
  'bg-gradient-blue-surf',
  'bg-dark-blue',
]

const CompareTab = ({ wrapperClassName = '', packages, services }) => {
  const [active, setActive] = useState('websites')
  const {
    CompareWebsiteAIX,
    CompareWebsiteAP,
    CompareWebsiteSC,
    CompareWebsiteIS,
    CompareServiceAgent,
    CompareServiceIS,
    CompareServiceIM,
    CompareServiceIDX,
    CompareServiceBackground,
  } = useStaticQuery(graphql`
    query compareImage {
      CompareWebsiteAIX: file(
        relativePath: { eq: "choose-a-website/aix.png" }
      ) {
        id
        ...CawcompareWebsiteImageImage
      }
      CompareWebsiteAP: file(relativePath: { eq: "choose-a-website/ap.png" }) {
        id
        ...CawcompareWebsiteImageImage
      }
      CompareWebsiteSC: file(relativePath: { eq: "choose-a-website/sc.png" }) {
        id
        ...CawcompareWebsiteImageImage
      }
      CompareWebsiteIS: file(relativePath: { eq: "choose-a-website/is.png" }) {
        id
        ...CawcompareWebsiteImageImage
      }
      CompareServiceAgent: file(
        relativePath: { eq: "choose-a-website/service-agent.jpg" }
      ) {
        id
        ...CawcompareServiceImageImage
      }
      CompareServiceIS: file(
        relativePath: { eq: "choose-a-website/service-is.jpg" }
      ) {
        id
        ...CawcompareServiceImageImage
      }
      CompareServiceIM: file(
        relativePath: { eq: "choose-a-website/service-im.jpg" }
      ) {
        id
        ...CawcompareServiceImageImage
      }
      CompareServiceIDX: file(
        relativePath: { eq: "choose-a-website/service-idx.jpg" }
      ) {
        id
        ...CawcompareServiceImageImage
      }
      CompareServiceBackground: file(
        relativePath: { eq: "choose-a-website/compare-service-mobile.jpg" }
      ) {
        id
        ...CawcompareServiceImageImage
      }
    }

    fragment CawcompareWebsiteImageImage on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          quality: 90
          formats: [AUTO]
          width: 328
          height: 278
        )
      }
    }

    fragment CawcompareServiceImageImage on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: NONE
          quality: 90
          formats: [AUTO]
          width: 360
          height: 248
        )
      }
    }
  `)

  let c = 0
  let siteItems = packages.map((site, index) => {
    let featureSet = site.items.concat(
      features.slice(site.items.length, features.length)
    )
    let gradient = siteGradient[c]
    c++
    return {
      gradient: gradient,
      image: modifyImageKeys(site.image),
      title: site.title,
      subtitle: site.description,
      features: featureSet,
      link: site.button_url,
    }
  })

  let serviceItems = services?.items.map((service, index) => {
    let subitems = []
    if (
      service.question_1 !== '' &&
      service.question_2 !== '' &&
      service.question_3 !== ''
    ) {
      subitems = [
        {
          title: service.question_1,
          description: service.answer_1,
        },
        {
          title: service.question_2,
          description: service.answer_2,
        },
        {
          title: service.question_3,
          description: service.answer_3,
        },
      ]
      return {
        image: modifyImageKeys(service.image),
        title: service.title,
        subtitle: service.subtitle,
        features: [
          {
            check: service.checkbox_1,
            content: service.description_1,
          },
          {
            check: service.checkbox_2,
            content: service.description_2,
            subItems: subitems,
          },
        ],
        link: '/',
      }
    } else {
      return {
        image: modifyImageKeys(service.image),
        title: service.title,
        subtitle: service.subtitle,
        description: service.description_1,
        link: '/',
      }
    }
  })

  return (
    <section className={`${cx.wrapper} ${wrapperClassName}`}>
      <div className={cx.buttons}>
        <button
          className={`subtitle-4 ${active === 'websites' ? cx.active : ''}`}
          onClick={() => {
            setActive('websites')
          }}
        >
          Compare Websites
        </button>
        <button
          className={`subtitle-4 ${active === 'service' ? cx.active : ''}`}
          onClick={() => {
            setActive('service')
          }}
        >
          Compare Service Plans
        </button>
      </div>

      <div className={cx.contentWrapper}>
        <div
          className={cx.contentWebsites}
          style={{ display: active === 'websites' ? 'flex' : 'none' }}
        >
          {siteItems.map((item, index) => {
            const image = getImage(item.image)

            return (
              <div key={index} className={cx.item}>
                <div className={cx.featuredImage}>
                  <ElementImageFile src={image} alt={item.title} lazy={true} />
                  {item.title === 'Semi-Custom' && (
                    <div className={`badge ${cx.badge}`}>
                      Most Popular <SvgStar />
                    </div>
                  )}
                </div>

                <div className={cx.content}>
                  <div className={`${cx.border} ${item.gradient}`}></div>
                  <h3 className="subtitle-2">{item.title}</h3>
                  <div className={`small-heading ${cx.subHeading}`}>
                    <strong>{parse(item.subtitle)}</strong>
                  </div>
                  <CheckItems
                    parentClass={cx.list}
                    items={item.features}
                    itemClass="default-body"
                  />
                  <Link to={item.link} className="primary-button">
                    Get Features & Pricing
                  </Link>
                </div>
              </div>
            )
          })}
        </div>

        <div
          className={cx.contentService}
          style={{
            display: active === 'service' ? 'flex' : 'none',
            backgroundImage: `url(${extractGatsbyImageDataRelativePath(
              CompareServiceBackground
            )})`,
          }}
        >
          {serviceItems.map((item, index) => {
            const image = getImage(item.image)

            return (
              <div key={index} className={cx.item}>
                <ElementImageFile src={image} alt={item.title} lazy={true} />

                <div className={cx.content}>
                  <h3 className="subtitle-3">{item.title}</h3>
                  <div className={`small-heading ${cx.subHeading}`}>
                    <strong>{parse(item.subtitle)}</strong>
                  </div>
                  {item.description !== undefined && (
                    <div className={cx.description}>
                      <p className="default-body">{parse(item.description)}</p>
                    </div>
                  )}
                  {item.features !== undefined && (
                    <CheckItems
                      parentClass={cx.list}
                      itemClass={`default-body ${cx.listItems}`}
                      items={item.features}
                    />
                  )}
                  <Link to={item.link} className="primary-button">
                    Get Features & Pricing
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

CompareTab.propTypes = {
  wrapperClassName: PropTypes.string,
  packages: PropTypes.any,
  services: PropTypes.any,
}

export default CompareTab
