import React from 'react'
import PropTypes from 'prop-types'

import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './AdditionalFeatures.module.scss'

const AdditionalFeatures = ({
  title = 'Additional Features',
  wrapperClassName = '',
  image,
  items,
}) => {
  return (
    <section className={`${cx.wrapper} ${wrapperClassName}`}>
      <ElementImageFile
        className={cx.image}
        src={image}
        alt={'Additional Features'}
        lazy={true}
      />
      <div className={cx.content}>
        <div className={cx.heading}>
          <h2 className="subtitle-2">{title}</h2>
        </div>

        <ul>
          {items.map((item, index) => {
            return (
              <li key={index}>
                <span className="default-body large">{item?.add_name}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

AdditionalFeatures.propTypes = {
  title: PropTypes.string,
  wrapperClassName: PropTypes.string,
  image: PropTypes.any,
  items: PropTypes.array,
}

export default AdditionalFeatures
