// extracted by mini-css-extract-plugin
export var border = "FeatureComparison-module--border--7a687";
export var checkSvg = "FeatureComparison-module--check-svg--00655";
export var content = "FeatureComparison-module--content--19c3f";
export var crossSvg = "FeatureComparison-module--crossSvg--2ab73";
export var desktop = "FeatureComparison-module--desktop--7ff25";
export var heading = "FeatureComparison-module--heading--02272";
export var headingBg = "FeatureComparison-module--heading-bg--85f5f";
export var mobile = "FeatureComparison-module--mobile--2faee";
export var overlay = "FeatureComparison-module--overlay--bd55e";
export var scrolled = "FeatureComparison-module--scrolled--53b03";
export var tableContent = "FeatureComparison-module--table-content--e63b6";
export var wrapper = "FeatureComparison-module--wrapper--8c4be";