import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './AwardWinning.module.scss'

import { modifyImageKeys } from '@utils/modifyImageKeys'

const AwardWinning = ({
  title = 'Are you ready for your own award-winning website?',
  description = 'Connect with us today to schedule a free design consultation. Whether you cater to luxury homebuyers or first-time homeowners, we’ve got a design package for you.',
  buttonLabel = 'Schedule a free consultation',
  buttonUrl = '/',
  background,
  gfx,
}) => {
  return (
    <section className={cx.wrapper}>
      <div className={cx.holder}>
        <div className={cx.text}>
          <h2 className="heading-3">{title}</h2>
          <p className="default-body text-neutral-200">{description}</p>
          <Link to={`${buttonUrl}`} className="primary-button large">
            {buttonLabel}
          </Link>
        </div>
        <div className={cx.image}>
          <ElementImageFile
            src={modifyImageKeys(gfx)}
            alt="Are you ready for your own award-winning website?"
            lazy={true}
          />
        </div>
      </div>
    </section>
  )
}

AwardWinning.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  background: PropTypes.object,
  gfx: PropTypes.object,
}

export default AwardWinning
