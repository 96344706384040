import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

// Component
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import Banner from '@components/global/banner/Banner'
import CompareTab from '@components/pages/choose-a-website/CompareTab'
import AwardWinning from '@components/pages/choose-a-website/AwardWinning'
import FeatureComparison from '@components/global/table/FeatureComparison'
import BuiltInPackages from '@components/pages/choose-a-website/BuiltInPackages'
import AdditionalFeatures from '@components/pages/choose-a-website/AdditionalFeatures'
import FormsBottomVersion1 from '@components/global/forms/bottom/Version1'

// Css
import * as cx from './ChooseAWebsite.module.scss'

// Images
import BannerBg from '/content/assets/choose-a-website/banner-bg.png'

// Image Model
import ImageFormBackground from '/content/assets/bottom-form/LCF04-BG.jpg'
import ImageFormModel from '/content/assets/bottom-form/LCF04-Model.jpg'
import { modifyImageKeys } from '@utils/modifyImageKeys'

const siteGradient = [
  'bg-gradient-aix',
  'bg-gradient-skys-the-limit',
  'bg-gradient-blue-surf',
  'bg-dark-blue',
]

const features = [
  {
    title: 'Hosted WordPress website',
  },
  {
    title: 'Fully responsive design',
  },
  {
    title: 'A pre-designed home page and inner page layouts',
  },
  {
    title: 'Slideshow',
  },
  {
    title: 'Video',
  },
  {
    title: 'Quick Search Form For Properties',
  },
  {
    title: 'Lead Capture Forms and Calls-To-Action',
  },
  {
    title:
      'Website Inner Pages include: <br />An About page with editable stock content using a pre-designed layout.',
  },
  {
    title:
      '3 property showcase pages with property details using pre-designed layouts',
  },
  {
    title: 'An About page using a pre-designed layout',
  },
  {
    title: 'A Profile or Team page using a pre-designed layout',
  },
  {
    title: 'Active Listings',
  },
  {
    title: 'Sold Listings',
  },
  {
    title: 'Search page',
  },
  {
    title: 'Property details page',
  },
  {
    title: 'Mortgage calculator',
  },
  {
    title: 'Lead capture forms',
  },
  {
    title: 'Social media sharing',
  },
  {
    title:
      'Community pages using a pre-designed layout, <br />limited to 5 to 7 communities only',
  },
  {
    title: 'Social media page using a pre-designed layout',
  },
  {
    title: 'Buyers page with editable stock content',
  },
  {
    title: 'Real estate news feed from Inman News',
  },
  {
    title: 'CRM Integration',
  },
  {
    title: 'Custom Logo Design',
  },
]

const ChoostAWebsite = ({ data: { page }, pageContext: { seo, uri } }) => {
  let c = 0
  let featureItems = []
  let headers = page?.websiteComparison?.features?.items.map((item, index) => {
    let gradient = siteGradient[c]
    c++
    return {
      title: item.site_title,
      gradient: gradient,
    }
  })
  let featuresItems = features.map((item, index) => {
    let subitems = page?.websiteComparison?.features?.items.map(
      (subitem, index) => {
        let checkItems = subitem.feature_items.find((needle) => {
          return needle.content == item.title
        })
        if (checkItems == undefined) {
          return false
        } else {
          return true
        }
      }
    )
    return {
      title: item.title,
      items: subitems,
    }
  })
  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <Banner
        layout="product-gallery"
        formLayout="default"
        formHeadingClassName="subtitle-6"
        bgImage={BannerBg}
        wrapperClassName={cx.banner}
        breadcrumbs={page?.seo?.breadcrumbs}
        subtitle={page?.websiteComparison?.banner?.title}
        title={page?.websiteComparison?.banner?.description}
        button_text={page?.websiteComparison?.banner?.formSubmitLabel}
        button_link="/"
        devices={{
          layout: 'LaptopTabletDiagonal',
          laptop: `${page?.websiteComparison?.banner?.laptop?.url}`,
          tablet: {
            one: `${page?.websiteComparison?.banner?.tablet?.url}`,
            two: `${page?.websiteComparison?.banner?.mobile?.url}`,
          },
        }}
      />
      <CompareTab
        packages={page?.websiteComparison?.packages}
        services={page?.websiteComparison?.services}
      />
      <AwardWinning
        title={page?.websiteComparison?.consultation?.title}
        description={page?.websiteComparison?.consultation?.description}
        buttonLabel={page?.websiteComparison?.consultation?.button_label}
        buttonUrl={page?.websiteComparison?.consultation?.button_url}
        background={page?.websiteComparison?.consultation?.background}
        gfx={page?.websiteComparison?.consultation?.image}
      />
      <FeatureComparison
        backgroundAsImageData={modifyImageKeys(
          page?.websiteComparison?.features?.background
        )}
        title={page?.websiteComparison?.features?.subtitle}
        mobileTitle={page?.websiteComparison?.features?.subtitle}
        subtitle={page?.websiteComparison?.features?.title}
        headerTitle="Features"
        headers={headers}
        features={featuresItems}
      />
      <BuiltInPackages
        title={page?.websiteComparison?.built_features?.title}
        subtitle={page?.websiteComparison?.built_features?.subtitle}
        image={modifyImageKeys(page?.websiteComparison?.built_features?.image)}
        items={page?.websiteComparison?.built_features?.items}
      />
      <AdditionalFeatures
        title={page?.websiteComparison?.additional_features?.title}
        image={modifyImageKeys(
          page?.websiteComparison?.additional_features?.image
        )}
        items={page?.websiteComparison?.additional_features?.items}
      />
      <FormsBottomVersion1
        background={ImageFormBackground}
        model={ImageFormModel}
        imageClassName={`${cx.model}`}
      />
    </LayoutInnerPage>
  )
}

export default ChoostAWebsite

export const pageQuery = graphql`
  query ChoostAWebsitePageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      seo {
        breadcrumbs {
          text
          url
        }
      }

      websiteComparison {
        banner {
          title
          description
          formTitle
          formSubmitLabel
          formSubtitle
          laptop {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          tablet {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          mobile {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        packages {
          title
          description
          button_text
          button_url
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            check
            content
          }
        }
        services {
          items {
            title
            subtitle
            image {
              url
              srcset
              sizes
              width
              height
              alt
              modified
            }
            description_1
            checkbox_1
            description_2
            checkbox_2
            description_3
            checkbox_3
            question_1
            answer_1
            question_2
            answer_2
            question_3
            answer_3
          }
        }
        consultation {
          title
          description
          button_label
          button_url
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
        }
        built_features {
          title
          subtitle
          image {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            built_in_name
          }
        }
        additional_features {
          title
          image {
            url
            width
            srcset
            sizes
            alt
            height
            modified
          }
          items {
            add_name
          }
        }
        features {
          title
          subtitle
          button_label
          button_url
          background {
            url
            srcset
            sizes
            width
            height
            alt
            modified
          }
          items {
            site_title
            feature_items {
              check
              content
            }
          }
        }
      }
    }
  }
`
