// extracted by mini-css-extract-plugin
export var active = "CompareTab-module--active--7994e";
export var badge = "CompareTab-module--badge--536ee";
export var border = "CompareTab-module--border--e83bf";
export var buttons = "CompareTab-module--buttons--3c292";
export var content = "CompareTab-module--content--9fcf1";
export var contentService = "CompareTab-module--content-service--bc596";
export var contentWebsites = "CompareTab-module--content-websites--fd39b";
export var contentWrapper = "CompareTab-module--content-wrapper--24107";
export var description = "CompareTab-module--description--662de";
export var featuredImage = "CompareTab-module--featured-image--36f94";
export var item = "CompareTab-module--item--648da";
export var list = "CompareTab-module--list--6c3e8";
export var listItems = "CompareTab-module--list-items--afcda";
export var subHeading = "CompareTab-module--sub-heading--59e10";
export var wrapper = "CompareTab-module--wrapper--ea4e9";