import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { wpautop } from '@utils/wpautop'
import { removeElementTags } from '@utils/removeElementTags'
import parse from 'html-react-parser'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

// CSS
import * as cx from './FeatureComparison.module.scss'

// SVG
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import SvgXCircle from '@svg/xCircle.inline'

const FeatureComparison = ({
  wrapperClassname = '',
  backgroundAsImageData,
  title,
  mobileTitle,
  subtitle,
  headerTitle,
  headers,
  features,
}) => {
  const [isScroll, setIsScroll] = useState(false)

  return (
    <section className={`${cx.wrapper} ${wrapperClassname}`}>
      <div className={`${cx.heading} heading`}>
        {backgroundAsImageData && (
          <ElementImageFile
            className={cx.headingBg}
            src={backgroundAsImageData}
            alt={removeElementTags(title)}
            lazy={false}
          />
        )}
        <p className="subtitle-7">{parse(subtitle)}</p>

        {mobileTitle === undefined && (
          <h2 className="heading-5">{parse(title)}</h2>
        )}
        {mobileTitle !== undefined && (
          <>
            <h2 className={`heading-5 ${cx.desktop}`}>{parse(title)}</h2>
            <h2 className={`heading-5 ${cx.mobile}`}>{parse(mobileTitle)}</h2>
          </>
        )}
      </div>

      <div className={`${cx.content} content`}>
        <div
          className={`${cx.tableContent} ${
            isScroll ? cx.scrolled : ''
          } table-content`}
        >
          <div
            className={cx.overlay}
            role="button"
            onClick={() => {
              setIsScroll(true)
            }}
          ></div>
          <table>
            <thead>
              <tr>
                <th className="small-heading strong">{headerTitle}</th>
                {headers.map((item, index) => {
                  return (
                    <th key={index} className="small-heading strong">
                      {item.title}

                      <span className={`${cx.border} ${item.gradient}`}></span>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {features.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span>{parse(item.title)}</span>
                    </td>
                    {item.items !== undefined &&
                      item.items.map((subitem, index) => {
                        return (
                          <td key={index}>
                            {subitem && (
                              <SvgCheckedCircle className={cx.checkSvg} />
                            )}
                            {!subitem && <SvgXCircle className={cx.crossSvg} />}
                          </td>
                        )
                      })}

                    {item.items === undefined &&
                      headers.map((item, index) => {
                        return (
                          <td key={index}>
                            <SvgCheckedCircle className={cx.checkSvg} />
                          </td>
                        )
                      })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <Link to="/" className="primary-button large">
          Schedule a free consultation&nbsp;
          <span className="desktop-only"> now</span>
        </Link>
      </div>
    </section>
  )
}

FeatureComparison.propTypes = {
  wrapperClassname: PropTypes.string,
  backgroundAsImageData: PropTypes.any,
  title: PropTypes.string,
  mobileTitle: PropTypes.string,
  subtitle: PropTypes.string,
  headers: PropTypes.array,
  headerTitle: PropTypes.string,
  features: PropTypes.array,
}

export default FeatureComparison
